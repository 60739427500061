import React, {useState, useEffect} from "react";
import {Link, Trans, useTranslation, useI18next, I18nextContext} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import Settings from '../../constants/company/settings';
import ContinentSettings from '../../constants/continents/geo-continents';
import LocksSupport from "../../components/ui/locks-support";
import AccordionWrapper from '../../components/ui/Accordions/AccordionWrapper';
import {globalHistory} from "@reach/router";
import hubspotSettings from "../../constants/company/settings";
import Cookies from "js-cookie";
import UpgraderModal from '../../components/modals/upgrader-modal';
import emailIcon from '../../images/shared/email-icon.svg';
import chatIcon from '../../images/shared/chat-icon.svg';


const IndexPage = (props) => {
  const isBrowser = typeof window !== "undefined";
  const {t} = useTranslation();
  const {changeLanguage} = useI18next();
  const context = React.useContext(I18nextContext);
  const [isSupport, setIsSupport] = useState(globalHistory.location.pathname.indexOf('support') > -1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    message: '',
    department: hubspotSettings.SUPPORT_EMAIL
  });
  const [emailIsClicked, setEmailIsClicked] = useState(false);
  const firstNameInputRef = React.useRef(null);
  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
  };
  const isFormValid = formData.email != null && formData.email.trim().length > 0;
  const [infoCurrent, setInfoCurrent] = useState({
    header: t('digilock_americas'),
    address: t('digilock_americas_address'),
    phone: Settings.PHONE_SALES_AMERICAS,
    callSales: isSupport ? t('call_support') : t('call_sales'),
    emailSales: t('email_support'),
    email: Settings.SUPPORT_EMAIL,
    liveChat: t('live_chat'),
    liveChatCopy: t('live_chat_copy'),
    tollFree: t('toll_free'),
    tollFreeNumber: Settings.PHONE_SALES_TOLL_FREE_AMERICAS,
    phoneNumber: Settings.PHONE_SALES_TOLL_FREE_AMERICAS
  });
  const [continent, setContinent] = useState(isBrowser ? sessionStorage.getItem(ContinentSettings.SESSION_CONTINENT) : 'North America');

  function handleInfoCurrentChange() {
    switch (continent) {
      case ContinentSettings.EUROPE:
        setInfoCurrent(prevState => ({
          ...prevState,
          ['header']: t('digilock_europe'),
          ['address']: t('digilock_europe_address'),
          ['phone']: Settings.PHONE_SALES_EUROPE,
          ['email']: Settings.SUPPORT_EMAIL_EUROPE,
          ['tollFree']: '',
          ['tollFreeNumber']: ''
        }));
        break;
      case ContinentSettings.ASIA:
        setInfoCurrent(prevState => ({
          ...prevState,
          ['header']: t('digilock_asia'),
          ['address']: t('digilock_asia_address'),
          ['phone']: Settings.PHONE_SALES_ASIA,
          ['email']: Settings.SUPPORT_EMAIL_ASIA,
          ['tollFree']: '',
          ['tollFreeNumber']: ''
        }));
        break;
      default:
        //code
    }
  }

  function handleScrollTo(elmID, offset) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: document.getElementById(elmID).offsetTop - offset
    });
  }

  const submit = (e) => {
    e.preventDefault();
    const postSubmit = hubspotSettings.CONTACT_FORM_SUPPORT;
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const body = {

      fields: [
        {
          name: 'firstname',
          value: formData.firstName,
        },
        {
          name: 'lastname',
          value: formData.lastName,
        },
        {
          name: 'company',
          value: formData.companyName,
        },
        {
          name: 'email',
          value: formData.email,
        },
        {
          name: 'phone',
          value: formData.phoneNumber,
        },
        {
          name: 'department',
          value: formData.department,
        },
        {
          name: 'message',
          value: formData.message,
        }
      ],

      context: {
        hutk,
        pageUri,
        pageName,
      },
    }

    fetch(postSubmit, {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      },
    })
        // .then(res => res.json())
        .then((res) => {

          handleSubmitSuccess();
        })
        .catch(err => {
          alert(err)
        })
  };
  let handleSubmitSuccess = () => {
    if (context.language !== 'en') {
      window.location.href = '/' + context.language + '/support/thank-you/';
    } else {
      window.location.href = '/support/thank-you/';
    }
  }

  function handleEmailClicked() {
    setEmailIsClicked(!emailIsClicked);
    document.getElementById('company').focus();
  }

  function handleSupportChatClick() {
    window.history.pushState({}, 'bot', '?bot=support');
    window.HubSpotConversations.clear({resetWidget: true});
  }

  const accordionFunctionality = props.data.allSupportGeneralFaqXlsxLockFunctionality.edges;
  const accordionProgramming = props.data.allSupportGeneralFaqXlsxSetupProgramming.edges;
  const accordionTroubleshooting = props.data.allSupportGeneralFaqXlsxTroubleShooting.edges;
  const accordionOrders = props.data.allSupportGeneralFaqXlsxOrdersSupport.edges;

  useEffect(() => {
    if (isBrowser) {
      handleInfoCurrentChange();
    }
    if (document.location.hash === '#gotoFaq') {
      handleScrollTo("genFaq", 150);
    }
    if (document.location.hash === '#gotoSmartLocks') {
      handleScrollTo("SmartLocks", 200);
    }
    if (document.location.hash === '#gotoElectronicLocks') {
      handleScrollTo("ElectronicLocks", 200);
    }
    if (document.location.hash === '#contactInfo') {
      handleScrollTo("contactInfo", 200);
    }
  }, []);

  return (
      <Layout>
        <Seo
            title={t('support_title')}
            description={t('des_support')}
        />
        <div className="support">
          <section className="hero">
            <div className="container">
              <h1>
                Digilock <Trans>product_support</Trans>
              </h1>
              <p dangerouslySetInnerHTML={{__html:t('product_support_subhead')}} />
            </div>
          </section>
          <LocksSupport/>
          <div id={'genFaq'} className="section-delimeter container"/>
          <section id={'genFaq'} className="general-faq">
            <div className="container">
              <h2 dangerouslySetInnerHTML={{__html:t('general_faq')}} className={'sub'} />
              <AccordionWrapper label={t('lock_functionality')} data={accordionFunctionality}/>
              <AccordionWrapper label={t('initial_setup')} data={accordionProgramming}/>
              <AccordionWrapper label={t('troubleshooting')} data={accordionTroubleshooting}/>
              <AccordionWrapper label={t('orders_support')} data={accordionOrders}/>
            </div>
          </section>
          <section id={'contactInfo'} className="contact-info">
            <div className="container">
              <div className={'contact-info-copy'}>
                <h1 dangerouslySetInnerHTML={{__html:t('get_support')}} />
                <p dangerouslySetInnerHTML={{__html:t('get_support_copy')}} />
              </div>
            </div>
            <div className="container contact-card-container">
              <div className={emailIsClicked ? 'contact-card hide' : 'contact-card'}>
                <img src={emailIcon} alt="email icon" width={'100'} height={'100'}/>
                <div>
                  <h4 dangerouslySetInnerHTML={{__html:t('email')}} />
                  <p dangerouslySetInnerHTML={{__html:t('email_product_expert')}} />
                </div>
                <a onClick={handleEmailClicked} href="javascript:void(null );" className="btn btn--orange">
                  <Trans>submit</Trans>
                </a>
              </div>
              <div className={emailIsClicked ? 'contact-card hide' : 'contact-card'}>
                <img src={chatIcon} alt="chat icon" width={'100'} height={'100'}/>
                <div>
                  <h4 dangerouslySetInnerHTML={{__html:t('chat')}} />
                  <p dangerouslySetInnerHTML={{__html:t('chat_with_product_expert')}} />
                </div>
                <a onClick={handleSupportChatClick} href="javascript:void(null);" className="btn btn--orange">
                  <Trans>submit</Trans>
                </a>
              </div>
              <div className={emailIsClicked ? 'sales-form show' : 'sales-form'}>
                <form id={'supportForm'}
                      className={'sales-contact-form'}
                      action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=00Dj0000001uqJu"
                      method="POST">
                  <div className="input-container company-name">
                    <label htmlFor={'company'}><Trans>company_name</Trans>*</label>
                    <input id="company" required="required" maxLength="80" name="company" type="text"/>
                  </div>
                  <div className="input-container first-name">
                    <label htmlFor={'first_name'}><Trans>first_name</Trans>*</label>
                    <input id="first_name" required="required" maxLength="80" name="first_name" type="text"/>
                  </div>
                  <div className="input-container first-name">
                    <label htmlFor={'last_name'}><Trans>last_name</Trans>*</label>
                    <input id="last_name" required="required" maxLength="80" name="last_name" type="text"/>
                  </div>
                  <div className="input-container email">
                    <label htmlFor={'email'}><Trans>email</Trans>*</label>
                    <input id="email" required="required" maxLength="80" name="email" type="text"/>
                  </div>
                  <div className="input-container phone-number">
                    <label htmlFor={'phone'}><Trans>phone_number</Trans></label>
                    <input id="phone" maxLength="40" name="phone" type="text"/>
                  </div>
                  <div className="input-container email">
                    <label htmlFor={'00N3Z00000CvIOl'}><Trans>Product Serial Number</Trans></label>
                    <input id="00N3Z00000CvIOl" maxLength="30" name="00N3Z00000CvIOl" type="text"/>
                  </div>
                  <div className="input-container phone-number">
                    <label htmlFor={'00N3Z00000CWiCF'}><Trans>Sales Order Number</Trans></label>
                    <input id="00N3Z00000CWiCF" maxLength="255" name="00N3Z00000CWiCF" type="text"/>
                  </div>
                  <div className="input-container phone-number">
                    <label htmlFor={'00N3Z00000CvIOv'}><Trans>Date of Purchase</Trans></label>
                    <input id="00N3Z00000CvIOv" maxLength="12" name="00N3Z00000CvIOv" placeholder={'mm/dd/yyyy'} type="text"/>
                  </div>
                  <div className="input-container phone-number">
                    <label htmlFor={'00N3Z00000CvIP5'}><Trans>Stock Code</Trans></label>
                    <input id="00N3Z00000CvIP5" maxLength="30" name="00N3Z00000CvIP5" type="text"/>
                  </div>
                  <div className="input-container phone-number">
                    <label htmlFor={'00N3Z00000CvIPF'}><Trans>Quantity</Trans></label>
                    <input id="00N3Z00000CvIPF" maxLength="20" name="00N3Z00000CvIPF" type="text"/>
                  </div>
                  <div className="input-container message">
                    <label htmlFor="description"><Trans>message</Trans></label>
                    <textarea name="description"
                              id={'description'}
                              maxLength="250"
                    />
                  </div>
                  <input type="hidden" name="oid" value="00Dj0000001uqJu"/>
                  <input type="hidden" name="lead_source" value="Website"/>
                  <input type="hidden" id="00NKZ000000VIW9" name="00NKZ000000VIW9" value="1"/>
                  <input type="hidden" id="00N3Z00000CvLhb" name="00N3Z00000CvLhb" value="Support Request Form"/>
                  <input
                      type="hidden"
                      name="retURL"
                      value={context.language !== 'en' ?
                          'https://www.digilock.com/' + context.language + '/support/thank-you/' :
                          'https://www.digilock.com/support/thank-you/'}
                  />
                  <div className="input-container-submit support">
                    <input
                        onClick={handleEmailClicked}
                        className={'form-submit cancel'}
                        type={'reset'}
                    >Cancel</input>
                    <input
                        className={'form-submit'}
                        type="submit"
                        name={'submit'}
                        // aria-disabled={!isFormValid ? 'true' : 'false'}
                    />
                  </div>
                </form>
              </div>
            </div>
          </section>
          <UpgraderModal showLink={false}/>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allSupportGeneralFaqXlsxLockFunctionality {
      edges {
        node {
          id
            title
            content
        }
      }
    }
    allSupportGeneralFaqXlsxSetupProgramming {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxTroubleShooting {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  
  allSupportGeneralFaqXlsxOrdersSupport {
    edges {
      node {
        id
          title
          content
      }
    }
  }
  }
`;
